
import InputTelephone from '@/components/input_telephone'
import { user_password } from '@/lib/validators'
import PasswordField from '@/components/form/password_field'

export default {
  components: { InputTelephone, PasswordField }
  data: -> {
    dialog: false,
    loading: false,
    login: '',
    server_error: '',
    token_code: '',
    token_id: '',
    password: '',
    confirm_password: '',
    reveal_password: false,
    reveal_password_confirm: false,
    step: 1,
    rules: {
      password_required: (value) => !!value || @$t('input_password_validate_required')
    }
  },

  computed: {
    compare_passwords: ->
      if @password != @confirm_password then @$t('form_validate_password_match') else true
    ,
    account: ->
      @$store.state.account.id
  },

  created: ->

  watch: {
    account: (id) ->
      @$router.push("/") if !!id
    ,
    dialog: (open) ->
      if !open
        @step = 1
        @login = ''
        @token_code = ''
        @server_error = ''
        @token_id = ''
        @password = ''
        @confirm_password = ''
  },

  methods: {
    request_reset: ->
      if @$refs.request_reset.validate()
        @loading = true;
        @server_error = null;
        @$store.dispatch('account/forgot_password', @login)
          .then (response) =>
            @loading = false;
            if response.status == 'ok'
              @step = 2
            else
              @server_error = response.message
          .catch =>
            @loading = false;
            console.error('on_request_reset:catch', error);
    ,
    validate_token: ->
      if @$refs.validate_token.validate()
        @loading = true;
        @server_error = null;
        @$store.dispatch('account/validate_password_reset_token', { token_code: @token_code, login: @login })
          .then (response) =>
            @loading = false;
            if response.status == 'ok'
              @token_id = response.token_id
              @step = 3
            else
              @server_error = response.message
          .catch () =>
            @loading = false;
            console.error('on_validate_token:catch', error);
    ,
    reset_password: ->
      if @$refs.reset_password.validate()
        @loading = true;
        @$store.dispatch('account/reset_password', { token_id: @token_id, password: @password })
          .then (response) =>
            @loading = false
            if response.status == 'ok'
              @$store.commit('account/set', response.account)
            else
              @server_error = response.message
          .catch () =>
            @loading = false;
            # console.error('on_reset_password:catch', error);
  }
};
